<template>
    <div>
        <div class="home-banner-bg">
            <div>
                <BannerTitleItem></BannerTitleItem>
            </div>
            <div class="bottomTab">
            </div>
        </div>
        <div style="display: flex;justify-content: center;">
            <div style="width: 1520px;">
                <div style="text-align: left;margin-top: 20px;font-size: 16px;">
                    <el-breadcrumb separator="/" style="padding-bottom: 20px;">
                        <el-breadcrumb-item :to="{ path: '/' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="fromPage==='sercher'" :to="{ path: '/enterprise/newsInfoLIst' }">建筑资讯
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/enterprise/newsDetail' }">建筑详情</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <template >

                    <div
                        style="margin-top: 40px;padding-bottom: 20px;border-bottom: #DCE5F2 solid 1px;margin-bottom: 40px;">
                        <div style="font-size: 32px;font-size: 20px;font-weight: bold;">{{newsInfo.informationTitle}}
                        </div>
                        <div
                            style="font-size:14px ;color: #888888;display: flex;justify-content: space-between;margin-top: 38px;">
                            <div style="display: flex;">
                                <div style="margin-left: 10px;margin-right: 30px;">来源：{{newsInfo.source||'-'}}</div>
                                <div style="margin-left: 10px;margin-right: 30px;">作者：{{newsInfo.author||'-'}}</div>
                                <div style="margin-left: 10px;margin-right: 30px;">发布时间：{{newsInfo.createTime}}</div>
                            </div>
                            <div>浏览量：{{newsInfo.viewNum||0}}</div>
                        </div>
                    </div>

                    <div style="display: flex;align-items: center;margin-top: 20px;" >
                        <div style="background: #8094C4;color: #FFFFFF;padding: 6px;margin-right: 16px;"
                            v-for="(item) in tagsList">{{item}}</div>
                    </div>

                    <div style="margin-top: 40px;font-size: 24px;font-weight: bold;">{{newsInfo.informationTitle}}</div>

                    <div style="margin-bottom: 30px;margin-top: 20px" v-html="newsInfo.informationDetailsText">
                    </div>
                </template>




            </div>
        </div>


    </div>

</template>

<script>
    import { getNewsDetial, } from "@/api/bid.js"
    import BannerTitleItem from '../components/banner-title-item/index.vue'
    export default {
        components: {
            BannerTitleItem
        },
        data() {
            return {
                newsInfo: '',
                informationId: '',
                fromPage: '',
                tagsList:[],
                loadingStatus: false,
            };
        },
       
        async created() {
            console.log('===========', this.$route?.query)
            this.informationId = this.$route?.query?.informationId
            this.fromPage = this.$route?.query?.fromPage
            this.getNewsDetial()
        },
        mounted() {

        },
        methods: {
            async getNewsDetial() {
                try {
                    this.loadingStatus = true
                    let { data } = await getNewsDetial({ informationId: this.informationId })

                    this.newsInfo = data
                     
                    if (data.tags) {
                        this.tagsList = data.tags.split(',')
                       
                    }

                    if(this.newsInfo.informationDetailsText){
                        this.newsInfo.informationDetailsText=this.unescape(this.newsInfo.informationDetailsText)
                    }
                    this.loadingStatus = false
                } catch (error) {
                    this.loadingStatus = false
                }
            },

            unescape (html) {
                return html
                    .replace(html ? /&(?!#?\w+;)/g : /&/g, "&amp;")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&#39;/g, "'");
            },



        },
    };
</script>

<style scoped>
    .line2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

    }

    .hotTag {
        background: #EDF4FF;
        padding: 8px;
        font-size: 16px;
        color: #1890FF;
        border-radius: 4px;

    }

    .sercher {
        color: #FFFFFF;
        background: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 24px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
    }

    .sercher-reset {
        border: 1px solid #1890FF;
        color: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 24px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
    }

    .reset {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #1890FF;
        color: #1890FF;
        padding: 8px 24px;
        font-size: 14px;
        cursor: pointer;
    }

    .sure {
        color: #FFFFFF;
        background: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 8px 24px;
        font-size: 14px;
        cursor: pointer;
    }
</style>